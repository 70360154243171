<template>
  <div
    :id="id"
    ref="container"
    class="root"
    :style="`--minWidth: ${!!minWidth ? `${minWidth}px` : '100%'}`"
  >
    <h3><slot /></h3>
    <svg
      :viewBox="`0 0 ${fullWidth} ${height}`"
      :style="`width: 100%; height: inherent`"
    >
      <g class="axis x-axis" />
      <g class="axis y-axis" />
      <g class="bars" />
      <g class="labels" />
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    margin: {
      type: Object,
      default: () => ({ top: 0, right: 140, bottom: 20, left: 164 }),
    },
    xDomain: {
      type: Array,
      default: () => [],
    },
    yDomain: {
      type: Array,
      default: () => [],
    },
    zDomain: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: [String, Array],
      default: () => 'var(--purple-light-2)',
    },
    animationDuration: {
      type: Number,
      default: 1000,
    },
    showStackLabels: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      fullWidth: 0,
    }
  },
  watch: {
    data: {
      handler() {
        this.plotGraph()
      },
      deep: true,
    },
  },
  mounted() {
    this.fullWidth = this.width || this.$refs.container.clientWidth
    if (this.minWidth && this.minWidth > this.fullWidth)
      this.fullWidth = this.minWidth
    this.plotGraph()
  },
  methods: {
    plotGraph() {
      let data = JSON.parse(JSON.stringify(this.data))
      if (this.zDomain.length === 0) {
        data = data.map((d) => {
          d.group = d.label
          return d
        })
      }

      const X = d3.map(data, (d) => d.amount)
      const Y = d3.map(data, (d) => d.label)
      const Z = d3.map(data, (d) => d.group)

      const yDomain =
        this.yDomain.length > 0
          ? new d3.InternSet([...this.yDomain])
          : new d3.InternSet(Y)
      const zDomain =
        this.zDomain.length > 0
          ? new d3.InternSet([...this.zDomain])
          : new d3.InternSet(Z)
      const colors = Array.isArray(this.colors)
        ? [...this.colors]
        : d3.range(zDomain.length).map(() => this.colors)

      const I = d3
        .range(X.length)
        .filter((i) => yDomain.has(Y[i]) && zDomain.has(Z[i]))

      // Group the data using the zDomain, join with original data
      // and filter empty groups
      const series = d3
        .stack()
        .keys(zDomain)
        .value(([, I], z) => X[I.get(z)])(
          d3.rollup(
            I,
            ([i]) => i,
            (i) => Y[i],
            (i) => Z[i]
          )
        )
        .map((s) =>
          s
            .map((d) => {
              const i = d.data[1].get(s.key)
              const _data = data.find(
                (d) => d.group === Z[i] && d.label === Y[i]
              )
              return Object.assign(d, { i, _data })
            })
            .filter((d) => d.i !== undefined)
        )
        .filter((s) => s.length !== 0)

      const xDomain =
        this.xDomain.length > 0
          ? new d3.InternSet([...this.xDomain])
          : d3.extent(series.flat(2))

      const xScale = d3
        .scaleLinear()
        .domain(xDomain)
        .range([this.margin.left, this.fullWidth - this.margin.right])
      const yScale = d3
        .scaleBand()
        .domain(yDomain)
        .range([this.height - this.margin.bottom, this.margin.top])
        .paddingInner(0.5)
        .paddingOuter(0.2)
      const color = d3.scaleOrdinal(zDomain, colors)

      const svg = d3.select(`#${this.id}`)

      // X axis
      const step = 5
      const min = d3.min(xDomain)
      const max = d3.max(xDomain)
      const stepValue = (max - min) / (step - 1)
      svg
        .select('.x-axis')
        .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
        .call(
          d3
            .axisBottom(xScale)
            .tickValues(d3.range(min, max + stepValue, stepValue))
            .tickSize(-this.height)
            .tickPadding(0)
        )
        .call((g) => g.select('.domain').remove())
        .selectAll('text')
        .attr('transform', `translate(0, 10)`)

      // Y axis
      svg
        .select('.y-axis')
        .attr('transform', `translate(${this.margin.left}, 0)`)
        .call(d3.axisLeft(yScale).tickSize(0))
        .selectAll('text')
        .attr('transform', `translate(-${this.margin.left}, 0)`)
        .attr('text-anchor', 'start')
        .attr('x', `0`)

      // Bars with animations
      svg
        .select('.bars')
        .selectAll('g')
        .data(series)
        .join('g')
        .attr('fill', ([{ i }]) => color(Z[i]))
        .selectAll('rect')
        .data((d) => d)
        .join('rect')
        .attr('x', xScale(0))
        .attr('y', ({ i }) => yScale(Y[i]))
        .attr('width', 0)
        .attr('height', yScale.bandwidth())
        .transition()
        .duration(this.animationDuration)
        .attr('x', ([x1, x2]) => Math.min(xScale(x1), xScale(x2)))
        .attr('width', ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)))

      if (this.showStackLabels) {
        const stackedBars = series
          .filter((s) => color(Z[s[0].i]) !== this.colors[0])
          .flat()

        svg
          .select('.labels')
          .selectAll('text')
          .data(stackedBars)
          .join('text')
          .attr('x', ([x1, x2]) => Math.max(xScale(x1), xScale(x2)) + 10)
          .attr('y', ({ i }) => yScale(Y[i]) + yScale.bandwidth() / 2)
          .attr('dy', '0.35em')
          .attr('dx', -4)
          .attr('text-anchor', 'start')
          .text((d) => d._data.groupLabel)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h3 {
  color: var(--grey-2);
  font-size: var(--font-lg);
  margin-bottom: 18px;
}

.root {
  overflow: auto;

  svg {
    min-width: var(--minWidth);
  }
}
</style>
