<template>
  <span
    v-if="studyTest"
    class="studyTest"
    :style="`background-color: ${currentColor || 'var(--grey-3)'}`"
    >{{
      studyTest.biomarker != null
        ? studyTest.biomarker.name
        : studyTest.genes.name
    }}</span
  >
  <div
    v-else
    :class="{
      inlineCustomOptimusContainer: !isTestCard,
      inlineCustomOptimusContainer2: isTestCard,
    }"
  >
    <div
      :class="{
        genesTest: isShortName,
        geneTestLarge: !isShortName,
      }"
    >
      <span class="material-icons custom-icon"> trip_origin</span>
      {{ name }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  components: {},
  props: {
    studyTest: {
      type: Object,
      required: false,
      defaultValue: [],
    },
    name: {
      type: String,
      required: false,
      defaultValue: '',
    },
    isTestCard: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
  },
  computed: {
    ...mapGetters('results', [
      'result',
      'results',
      'errors',
      'hasErrors',
      'isNew',
    ]),
    ...mapState('results', ['results']),

    currentColor() {
      let test = this.studyTest.resultId
      let color =
        this.results.find((r) => r.id == test) != undefined
          ? this.results.find((r) => r.id == test).color
          : 'var(--grey-3)'
      return color
    },
    isShortName() {
      return this.name.length <= 8
    },
  },
  async mounted() {
    await this.getResults()
  },
  methods: {
    ...mapActions('results', ['getResults']),
    colorForGeneResult(gene_id) {
      return this.results.find((r) => r.id == gene_id)
    },
  },
}
</script>

<style lang="scss" scoped>
.studyTest {
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 80px;
  transition: 0.2s;
}

.studyTest:hover {
  max-width: 100%;
}

.genesTest {
  font-size: 12px;
  padding: 3px 8px;
  padding-bottom: 4px;
  // text-align: center;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flexbox;
  background-color: var(--dark-blue-2);
  width: 5rem;
  transition: width 0.6s ease-in-out;
  animation-fill-mode: forwards;
}

.genesTest:hover {
  font-size: 12px;
  padding: 3px 8px;
  padding-bottom: 4px;
  // text-align: center;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flexbox;
  width: 6rem;
}
.geneTestLarge {
  font-size: 12px;
  padding: 3px 8px;
  padding-bottom: 4px;
  // text-align: center;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  // bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flexbox;
  background-color: var(--dark-blue-2);
  width: 7rem;
  transition: width 0.6s ease-in-out;
  animation-fill-mode: forwards;
}
.geneTestLarge:hover {
  font-size: 12px;
  padding: 1px 8px;
  padding-bottom: 4px;
  // text-align: center;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  // bottom: 10rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flexbox;
  width: 12rem;
  content: inherit;
}
.custom-icon {
  display: inline-block;
  vertical-align: middle;
  align-content: left;
  padding-right: 3%;
  padding-bottom: 1%;
  font-size: 14px;
}
.genes-result-list {
  border-radius: 12.5px;
  text-overflow: ellipsis;
  border-color: #00dc9c;
  color: #00dc9c;
}
.inlineCustomOptimusContainer {
  position: absolute;
  // margin-top: 10px;
}
.inlineCustomOptimusContainer2 {
  position: relative;
}
</style>
