<template>
  <div class="m-chckbox--container" :class="[classes]">
    <div
      class="m-chckbox--group"
      :style="mainStyle + sizeStyles"
      @click="() => !disabled && $emit('change', value)"
    >
      <div v-if="checkboxState" class="material-icons">check</div>
      <div v-ripple class="ripple" :style="rippleSizeStyles">
        <input
          :id="id || uniqueId"
          type="checkbox"
          :name="name"
          :value="value"
          :disabled="disabled"
          :required="required"
          :color="color"
          :checked="checkboxState"
        />
      </div>
    </div>
    <label
      v-if="$slots.default"
      :style="fontSizeStyles"
      class="m-chckbox--label"
      :for="id || uniqueId"
    >
      <slot />
    </label>
  </div>
</template>

<script>
// component taken from https://github.com/xrei/vue-material-checkbox/
import ripple from '@/utils/ripple'
export default {
  directives: {
    ripple,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    model: {
      type: [Boolean, Array],
      default: undefined,
    },
    checked: Boolean,
    value: {
      type: [String, Boolean, Number, Object, Array, Function],
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    required: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      default: 'var(--royal-blue)',
    },
    size: {
      type: Number,
      default: undefined,
    },
    fontSize: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      uniqueId: '',
      lv: this.model,
    }
  },
  computed: {
    checkboxState() {
      if (Array.isArray(this.model)) {
        if (typeof this.value === 'object')
          return !!this.model.find((m) => m.id === this.value.id)
        return this.model.indexOf(this.value) !== -1
      }
      return this.model || Boolean(this.lv)
    },
    classes() {
      return {
        disabled: this.disabled,
        active: this.checkboxState,
      }
    },
    mainStyle() {
      return this.checkboxState
        ? this.color &&
            `background-color: ${this.color}; border-color: ${this.color};`
        : ''
    },
    sizeStyles() {
      return this.size ? `width: ${this.size}px; height: ${this.size}px; ` : ''
    },
    rippleSizeStyles() {
      return this.size
        ? `width: ${this.size + 28}px; height: ${this.size + 28}px;`
        : ''
    },
    fontSizeStyles() {
      return this.fontSize ? `font-size: ${this.fontSize}px` : ''
    },
  },
  watch: {
    model(v) {
      this.lv = v
    },
  },
  mounted() {
    this.genId()
  },
  methods: {
    genId() {
      if (this.id === undefined || typeof String) {
        this.uniqueId = `m-checkbox--${Math.random()
          .toString(36)
          .substring(2, 10)}`
      } else {
        this.uniqueId = this.id
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$size: 20px;
$default-check-color: #fff;
.m-chckbox--container {
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  margin: 1rem 0;
  margin-right: 1rem;
  width: 100%;
  line-height: 13px;
  align-items: center;
}
.m-chckbox--ripple {
  box-sizing: border-box;
  z-index: 1;
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.m-chckbox--label {
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
  margin: 0;
}
.m-chckbox--group {
  box-sizing: border-box;
  position: relative;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.54);
  height: 20px;
  width: 20px;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  .material-icons {
    color: white;
    font-size: 16px;
  }
}
.m-chckbox--group input[type='checkbox'] {
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  left: -999rem;
}
.m-chckbox--container.disabled {
  cursor: not-allowed;
}
.m-chckbox--container.disabled .m-chckbox--group {
  opacity: 0.14;
}
.m-chckbox--container.disabled .m-chckbox--label {
  opacity: 0.24;
  cursor: not-allowed;
}
</style>
