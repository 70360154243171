<template>
  <d-card
    class="study p-4 h-100"
    @click="
      () => $router.push({ name: NAMED_ROUTES.STUDY, params: { id: study.id } })
    "
  >
    <d-row class="mb-1">
      <d-col cols="12" lg="6" class="align-self-center">
        <h5>{{ study.patient.firstName }} {{ study.patient.lastName }}</h5>
      </d-col>
      <d-col cols="6" class="text-lg-right d-none d-lg-block">
        Estudio #{{ study.id }}
        <d-dropdown
          v-if="
            study.studyTests &&
            study.studyTests.length &&
            hasFiles(study.studyTests)
          "
          text="download"
          size="sm"
          toggle-class="text-body download-button"
          theme="light"
          no-caret
          right
        >
          <d-dropdown-item
            v-for="studyTest in study.studyTests.filter(
              (test) => test.resultsFile
            )"
            :key="studyTest.id"
            :href="studyTest.resultsFile"
            target="_blank"
          >
            {{
              studyTest.biomarker != null
                ? studyTest.biomarker.name
                : umbrellaName
            }}
          </d-dropdown-item>
        </d-dropdown>
      </d-col>
    </d-row>
    <div class="mb-3">
      {{ primaryTumorName }}
    </div>
    <div v-if="!study.umbrellaId" class="mb-3 studyTests">
      <study-test-pill
        v-for="studyTest in study.studyTests"
        :key="studyTest.id"
        :study-test="studyTest"
        :name="null"
      />
    </div>
    <div v-else class="mb-3">
      <div class="inline custom-inline">
        <study-test-pill
          v-if="study.studyTests.find((st) => st.genes != null)"
          :study-test="null"
          :name="umbrellaName"
          :is-test-card="true"
        />
        <div class="custom-test-container">
          <study-test-pill
            v-for="studyTest in study.studyTests.slice(0, 6)"
            :key="studyTest.id"
            :study-test="studyTest"
            :name="null"
          />
          <span
            v-if="study.studyTests.length > 6"
            title="Ver mas resultados"
            class="numberPill"
            :class="numberPillClasess"
            @mouseover="openTestDescription"
            @mouseleave="closeTestDescription"
          >
            + {{ moreResultsLength }}
          </span>
        </div>
      </div>
    </div>
    <d-row class="form-group align-items-center">
      <d-col cols="12" lg="6">
        <label>Fecha de pedido</label>
      </d-col>
      <d-col cols="12" lg="6" class="text-lg-right">
        {{ study.createdAt | moment('DD/MM/YY') }}
      </d-col>
    </d-row>
    <d-row class="align-items-center">
      <d-col cols="12" lg="6">
        {{ statusLabels[study.status] }}
      </d-col>
      <d-col
        v-if="study.statusUpdatedAt"
        cols="12"
        lg="6"
        class="text-lg-right"
      >
        {{ study.statusUpdatedAt | moment('DD/MM/YY') }}
      </d-col>
    </d-row>
    <div v-if="study.fromCsv" class="pt-3">
      <span>Estudio creado desde un CSV</span>
    </div>
    <d-row
      v-if="
        study.studyTests &&
        study.studyTests.length &&
        hasFiles(study.studyTests)
      "
      class="d-block d-lg-none mt-3"
    >
      <d-col>
        <label>Informes</label>
        <d-dropdown
          class="d-block"
          theme="success"
          text="Descargar"
          size="sm"
          toggle-class="material-icons"
        >
          <!-- <div v-if="study.studyTests.filter((st) => st.biomarker != null)"> -->
          <d-dropdown-item
            v-for="studyTest in study.studyTests.filter(
              (test) => test.resultsFile
            )"
            :key="studyTest.id"
            :href="studyTest.resultsFile"
            target="_blank"
          >
            {{
              studyTest.biomarker != null
                ? studyTest.biomarker.name
                : umbrellaName
            }}
          </d-dropdown-item>
          <!-- </div> -->
        </d-dropdown>
      </d-col>
    </d-row>
  </d-card>
</template>

<script>
import { mapGetters } from 'vuex'
import StudyTestPill from '@/components/StudyTestPill.vue'

export default {
  components: { StudyTestPill },
  props: {
    study: {
      type: Object,
      required: true,
    },
    statusLabels: {
      type: Object,
      required: true,
    },
    umbrellaName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      numberPillClasess: [],
      testDescriptionShow: false,
    }
  },

  computed: {
    ...mapGetters('primaryTumors', ['primaryTumors']),
    moreResultsLength() {
      return this.study.studyTests.length - 6
    },
    primaryTumorName() {
      const tumor = this.primaryTumors.find(
        (tumor) => tumor.id === this.study.primaryTumorId
      )
      if (!tumor) return 'Tumor no encontrado'
      return tumor.name
    },
  },
  async mounted() {},
  methods: {
    hasFiles(studyTests) {
      return studyTests.some(
        (test) => test.resultsFile && test.resultsFile.length
      )
    },
    openTestDescription() {
      this.numberPillClasess = ['numberPill2']
      this.testDescriptionShow = true
    },
    closeTestDescription() {
      this.numberPillClasess = ['numberPill']
      this.testDescriptionShow = false
    },
  },
}
</script>

<style lang="scss">
.custom-inline {
  padding-top: 2px;
  background-color: var(--dark-blue-2);
  border-radius: 12.5px;
  max-height: 90%;
  width: inherit;
  // max-width: 99%;
  gap: 1.2rem;
}
.numberPill {
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: auto;
  transition: 0.2s;
  background-color: var(--purple-light-2);
}
.numberPill2 {
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12.5px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: auto;
  transition: 0.2s;
  background-color: var(--purple-light-3);
}
.custom-test-container {
  padding-left: 2px;
  padding-top: 3px;
  padding-right: 3px;
  border-radius: 8px;
  position: sticky;
  background-color: white;
  width: 90%;
}
.study {
  cursor: pointer;
}
.studyTests {
  display: flex;
  gap: 6px;
}

.btn.download-button {
  font-size: 1.5rem;
  font-family: 'material icons' !important;
}
</style>
