<template>
  <div :id="id" ref="container" class="bar">
    <h3><slot /></h3>
    <svg
      :viewBox="`0 0 ${fullWidth} ${height}`"
      style="max-width: 100%; height: intrinsic"
    >
      <g class="axis x-axis" />
      <g class="axis y-axis" />
      <g class="bars" />
      <g class="labels" />
    </svg>
  </div>
</template>
<script>
import * as d3 from 'd3'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    margin: {
      type: Object,
      default: () => ({ top: 16, right: 0, bottom: 16, left: 0 }),
    },
    xDomain: {
      type: Array,
      default: () => [],
    },
    yDomain: {
      type: Array,
      default: () => [],
    },
    zDomain: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: [String, Array],
      default: () => 'var(--purple-light-2)',
    },
    animationDuration: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      fullWidth: 0,
    }
  },
  watch: {
    data: {
      handler() {
        this.plotGraph()
      },
      deep: true,
    },
  },
  mounted() {
    this.fullWidth = this.width || this.$refs.container.clientWidth
    this.plotGraph()
  },
  methods: {
    plotGraph() {
      let data = JSON.parse(JSON.stringify(this.data))
      let _zDomain = [...this.zDomain]

      if (_zDomain.length === 0) {
        data = data.map((d) => {
          d.group = d.label
          return d
        })
        _zDomain = d3.map(data, (d) => d.group)
      }

      const X = d3.map(data, (d) => d.label)
      const Y = d3.map(data, (d) => d.amount)
      const Z = d3.map(data, (d) => d.group)
      const xDomain =
        this.xDomain.length > 0
          ? new d3.InternSet([...this.xDomain])
          : new d3.InternSet(X)
      const zDomain = new d3.InternSet(_zDomain)
      const colors = Array.isArray(this.colors)
        ? [...this.colors]
        : d3.range(_zDomain.length).map(() => this.colors)
      const I = d3
        .range(X.length)
        .filter((i) => xDomain.has(X[i]) && zDomain.has(Z[i]))

      const series = d3
        .stack()
        .keys(zDomain)
        .value(([, I], z) => Y[I.get(z)])(
          d3.rollup(
            I,
            ([i]) => i,
            (i) => X[i],
            (i) => Z[i]
          )
        )
        .map((s) =>
          s
            .map((d) => Object.assign(d, { i: d.data[1].get(s.key) }))
            .filter((d) => d.i !== undefined)
        )
        .filter((s) => s.length !== 0)

      const yDomain =
        this.yDomain.length > 0
          ? new d3.InternSet([...this.yDomain])
          : d3.extent(series.flat(2))
      const xScale = d3
        .scaleBand()
        .domain(xDomain)
        .range([this.margin.left, this.fullWidth - this.margin.right])
        .paddingInner(0.5)
        .paddingOuter(0.2)
      const yScale = d3
        .scaleLinear()
        .domain(yDomain)
        .range([this.height - this.margin.bottom, this.margin.top])
      const color = d3.scaleOrdinal(zDomain, colors)

      const svg = d3.select(`#${this.id}`)

      // X axis
      svg
        .select('.x-axis')
        .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
        .call(d3.axisBottom(xScale).tickSize(0))

      // Y axis
      const step = 5
      const min = d3.min(yDomain)
      const max = d3.max(yDomain)
      const stepValue = (max - min) / (step - 1)
      svg
        .select('.y-axis')
        .attr('transform', `translate(${this.margin.left}, 0)`)
        .call(
          d3
            .axisLeft(yScale)
            .tickValues(d3.range(min, max + stepValue, stepValue))
            .tickSize(-this.fullWidth)
            .tickPadding(0)
        )
        .selectAll('.tick text')
        .attr('dy', '14px')
        .attr('x', '0')
        .style('text-anchor', 'start')

      // Bars
      svg
        .select('.bars')
        .selectAll('g')
        .data(series)
        .join('g')
        .attr('fill', ([{ i }]) => color(Z[i]))
        .selectAll('rect')
        .data((d) => d)
        .join('rect')
        .attr('x', ({ i }) => xScale(X[i]))
        .attr('y', ([y1, y2]) => Math.max(yScale(y1), yScale(y2)))
        .attr('width', xScale.bandwidth())
        .attr('height', 0)
        .transition()
        .duration(this.animationDuration)
        .attr('y', ([y1, y2]) => Math.min(yScale(y1), yScale(y2)))
        .attr('height', ([y1, y2]) => Math.abs(yScale(y1) - yScale(y2)))
    },
  },
}
</script>

<style lang="scss">
.bar {
  & h3 {
    color: var(--grey-2);
    font-size: var(--font-lg);
    margin-bottom: 18px;
  }

  .y-axis {
    & path {
      stroke: transparent;
    }

    & .tick:first-of-type text {
      display: none;
    }
  }
}
</style>
