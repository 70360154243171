<template>
  <aside
    :class="[
      'main-sidebar',
      'col-12',
      'col-md-3',
      'col-lg-2',
      'px-0',
      sidebarVisible ? 'open' : '',
    ]"
  >
    <div class="main-navbar">
      <nav
        class="
          d-flex
          justify-content-center
          navbar
          align-items-center
          navbar-light
          p-0
        "
      >
        <div class="button-link" @click="navigateBack">
          <i
            v-if="displayBackButton"
            class="material-icons d-sm-inline d-md-none"
            >arrow_back</i
          >
        </div>
        <router-link
          class="navbar-brand flex-grow-1 mr-0"
          :to="{ name: DEFAULT_NAMED_VIEW_BY_ROLE[role] }"
        >
          <img
            id="main-logo"
            src="@/assets/images/logos/pluton-2x-white-sidebar.png"
            alt="Home"
          />
          <span v-if="!hideLogoText" class="d-md-inline ml-1" />
        </router-link>
        <div class="button-link toggle-sidebar" @click="handleToggleSidebar()">
          <i class="material-icons d-sm-inline d-md-none">close</i>
        </div>
      </nav>
    </div>

    <div v-if="items" class="nav-wrapper">
      <div v-for="(nav, navIdx) in items" :key="navIdx" class="section">
        <div
          v-if="
            nav.title &&
            !!nav.items &&
            nav.items.length &&
            userIsAuthorized(nav, $auth)
          "
        >
          <h6 class="main-sidebar__nav-title">
            {{ nav.title }}
          </h6>
          <d-nav class="nav--no-borders flex-column">
            <li
              v-for="(item, navItemIdx) in nav.items.filter(
                (item) =>
                  userIsAuthorized(item, $auth) && !excludeItem(item, $auth)
              )"
              :key="navItemIdx"
              class="nav-item dropdown"
              @click="handleToggleSidebar"
            >
              <router-link :to="{ name: item.to }" tag="div">
                <a
                  v-d-toggle="`snc-${navIdx}-${navItemIdx}`"
                  :class="[
                    'nav-link',
                    item.items && item.items.length ? 'dropdown-toggle' : '',
                  ]"
                  href="#"
                >
                  <div v-if="item.icon" class="item-icon-wrapper">
                    <i class="material-icons">{{ item.icon }}</i>
                  </div>
                  <span v-if="item.title">{{ item.title }}</span>
                </a>
              </router-link>

              <component
                :is="item.nested"
                v-if="shouldDisplayNested(item)"
                class="nav-link d-none d-md-block"
              />

              <d-collapse
                v-if="item.items && item.items.length"
                :id="`snc-${navIdx}-${navItemIdx}`"
                class="dropdown-menu dropdown-menu-small"
                accordion="sidebar-items-accordion"
              >
                <d-dropdown-item
                  v-for="(subItem, subItemIdx) in item.items"
                  :key="subItemIdx"
                  :href="subItem.href"
                  :to="subItem.to"
                >
                  {{ subItem.title }}
                </d-dropdown-item>
              </d-collapse>
            </li>
          </d-nav>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

import { DEFAULT_NAMED_VIEW_BY_ROLE } from '@/utils/constants'
import StudyFilters from '@/components/StudyFilters'

export default {
  name: 'MainSidebar',
  components: {
    StudyFilters,
  },
  props: {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: {
      type: Boolean,
      default: false,
    },
    /**
     * The menu items.
     */
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sidebarVisible: false,
      DEFAULT_NAMED_VIEW_BY_ROLE,
    }
  },
  computed: {
    ...mapGetters('auth', ['role']),
    displayBackButton() {
      return (
        this.$route.name !== this.DEFAULT_NAMED_VIEW_BY_ROLE[this.role] &&
        window.history.length
      )
    },
  },
  created() {
    this.$eventHub.$on('toggle-sidebar', this.handleToggleSidebar)
  },
  beforeDestroy() {
    this.$eventHub.$off('toggle-sidebar')
  },
  methods: {
    handleToggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible
    },
    userIsAuthorized(item, auth) {
      return (
        !item['requiresUserType'] ||
        item['requiresUserType'].includes(auth.user().relationType)
      )
    },
    excludeItem(item, auth) {
      //eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('excludeIf')) {
        return !!auth
          .user()
          .roles.find((role) =>
            item.excludeIf.map((itm) => itm.role).includes(role.roleType)
          )
      }
      return false
    },
    shouldDisplayNested(item) {
      return item.nested && item.displayCondition(this)
    },
    navigateBack() {
      if (this.$route.name === this.DEFAULT_NAMED_VIEW_BY_ROLE[this.role])
        return
      this.sidebarVisible = false
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
.main-sidebar {
  .navbar {
    background-color: var(--royal-blue);
    * {
      height: 100%;
    }
    .button-link {
      text-decoration: none;
      color: white;
      font-size: 1.75rem;
      padding: 1rem;
      width: 3.75rem;
      height: 3.75rem;
    }
    .toggle-sidebar {
      cursor: pointer;
    }
    .navbar-brand {
      display: flex;
      justify-content: center;
      padding: 5px;
    }
  }
}

.main-sidebar {
  .item-icon-wrapper {
    display: inline-block;
  }
  .dropdown-menu {
    display: block;
  }
  .nav-wrapper .section {
    margin-bottom: 1rem;
  }

  .nav .nav-item.dropdown,
  .nav .nav-link {
    overflow: visible;
  }

  .nav-wrapper {
    overflow-x: visible;
  }

  .vdp-datepicker {
    white-space: normal;
  }

  .vdp-datepicker__calendar {
    padding: 0.5rem;
    width: 100%;

    .cell {
      padding: 0;
      &.day-header {
        font-size: 0rem;
        width: 14%;
        height: 1.5rem;

        &::first-letter {
          font-size: 0.7rem;
        }
      }
      &.day {
        width: 14%;
        height: 1.5rem;
        font-size: 0.8rem;
        border-radius: 2px;
      }
      &.month {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .created-at-from .vdp-datepicker__calendar {
    width: calc(200% + 0.5rem);
    right: calc((100% + 0.5rem) * -1);
  }
  .created-at-to .vdp-datepicker__calendar {
    width: calc(200% + 0.5rem);
    left: calc((100% + 0.5rem) * -1);
  }
}
</style>
